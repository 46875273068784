import React from 'react';
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import get from 'lodash/get';
import { linkResolver } from 'gatsby-source-prismic-graphql';
import { RichText } from 'prismic-reactjs';

const TatouageTemplate = (props) => {

  const name = props.data.prismic.allTatouagess.edges[0].node.name;
  const description = props.data.prismic.allTatouagess.edges[0].node.description;
  
  const photos = get(props.data, 'prismic.allTatouagess.edges[0].node.photos');
  
  const prev = get(props.data, 'prismic.prevTatouage');
  const next = get(props.data, 'prismic.nextTatouage');
 
  const titleSeo = props.data.prismic.allTatouagess.edges[0].node.title_seo;
  const descriptionSeo = props.data.prismic.allTatouagess.edges[0].node.description_seo;

  console.log(props)

 
  return (
    <Layout>
     <SEO 
         title={(titleSeo) ? titleSeo : ''}
         description={(descriptionSeo) ? descriptionSeo : ''}
      />
      <div className="l-illustration">
        <div className="container">
          <p className="m-return l-illustration__return">
            <Link to="/tatouages" className="m-return__link">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.823 240.823">
                <path d="M57.633 129.007L165.93 237.268c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0L57.621 111.816c-4.679 4.691-4.679 12.511.012 17.191z"/>
              </svg>
              Retour
            </Link>
          </p>
          <div className="l-illustration__header">
            <div className="l-illustration__header__title">{RichText.render(name)}</div>
    
            <div className="l-illustration__header__text">
              {RichText.render(description)}
            </div>
          </div>
          <div className="l-illustration__photos">
            {photos.map( (photo,index) => {
                let photoSharp = get(photo, 'photoSharp.childImageSharp.fluid');
                
                return (
                  <div key= {index} className="l-illustration__photos__item">
                    <Img  fluid={photoSharp}/>
                  </div>
                )
            })}
            
            </div>
 
            <div className="m-pagination">
              <div className="m-pagination__wrapper">
                { prev !== null &&
                  <div className="m-pagination__item">
                      <Link to={linkResolver(prev._meta)} aria-label="Tatouage précedent" className="m-pagination__item__link m-pagination__item__link--prev">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.823 240.823">
                        <path d="M57.633 129.007L165.93 237.268c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0L57.621 111.816c-4.679 4.691-4.679 12.511.012 17.191z"/>
                      </svg>
                        Précédent
                      </Link>
                  </div>
                  }
                { next !== null &&
                  <div className="m-pagination__item">
                    <Link to={linkResolver(next._meta)} aria-label="Tatouage suivant" className="m-pagination__item__link m-pagination__item__link--next">
                        Suivant
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.823 240.823">
                          <path d="M57.633 129.007L165.93 237.268c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0L57.621 111.816c-4.679 4.691-4.679 12.511.012 17.191z"/>
                        </svg>
                    </Link>
                  </div>
                }
              </div>
            </div>
        </div>
      </div> 
      
    </Layout>  
  )
}
  
export default TatouageTemplate



export const query = graphql`
  
  query Tatouage(
    $uid: String!
    $paginationPreviousUid: String!
    $paginationNextUid: String!
    ) {
    prismic { 
      allTatouagess(uid: $uid) {
        pageInfo { 
          hasNextPage
          hasPreviousPage
          endCursor
          startCursor
        }
        edges {
          node {
            _meta {
              uid
            }
            name
            description 
            photos {
              photo
              photoSharp {
                childImageSharp {
                  fluid(maxWidth: 1200, quality  :100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            } 
          }
          cursor
        } 
      }
      prevTatouage: tatouages(uid: $paginationPreviousUid, lang: "fr-fr") {
        name
        _meta {
          uid
          lang
          type
        }
      }
      nextTatouage: tatouages(uid: $paginationNextUid, lang: "fr-fr") {
        name
        _meta {
          uid
          lang
          type
        }
      }
    }
  }
`; 


